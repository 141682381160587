import React from "react";
import {CMSPage, InspirationLayout} from "benlux-ecommerce-ui";
import {CmsPageQuery} from "../../../queries/CmsPageQuery";
import {Loader} from "@deity/falcon-ecommerce-uikit";
import Helmet from "react-helmet";

const Inspiration = ({ id, path, type }) => {
  return (
    <CmsPageQuery variables={{id}}>
      {({ cmsPage, networkStatus, loading }) => {
        if (!cmsPage && loading) {
          return <Loader/>;
        }

        const {content, metaTitle, metaDescription} = cmsPage;
        const inspiration = JSON.parse(content);
        const description = metaDescription || inspiration.description || '';
        const title = metaTitle || inspiration.title || '';
        const image = inspiration.cover;

        return (
          <>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={description}/>
              <meta name="page-loaded" content="true" />
              <meta property="og:title" content={title + ' ‣ BENLUX'}/>
              <meta property="og:type" content="website"/>
              <meta property="og:description" content={description}/>
              <meta property="og:url" content={'https://www.benlux.fr' + path}/>
              {image && (<meta name="og:image" content={image}/>)}
              <meta name="twitter:card" content="summary_large_image"/>
              <meta property="twitter:domain" content="benlux.fr"/>
              <meta property="twitter:url" content={'https://www.benlux.fr' + path}/>
              <meta name="twitter:title" content={title + ' ‣ BENLUX'}/>
              <meta name="twitter:description" content={description}/>
              {image && (<meta name="twitter:image" content={image}/>)}
              <meta name="page-loaded" content="true" />
            </Helmet>
            <InspirationLayout inspiration={inspiration} />
          </>
        );
      }}
    </CmsPageQuery>
  )
};

export default Inspiration;
